import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  inject,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import {
  PowerBIEmbedModule,
  PowerBIReportEmbedComponent,
} from 'powerbi-client-angular';
import { Subject, combineLatest, from, takeUntil } from 'rxjs';
import { MaximizeDirective } from '../../shared/directives/maximize.directive';
import { PowerBiApi } from '../../shared/models/power-bi-models';
import { ApplicationStateService } from '../../shared/services/application-state.service';
import * as pbi from 'powerbi-client';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  standalone: true,
  imports: [PowerBIEmbedModule, CommonModule, MaximizeDirective],
})
export class ReportComponent implements OnInit {
  private _destroying$ = new Subject<void>();

  powerbi: pbi.service.Service = window['powerbi'];

  @ViewChild(PowerBIReportEmbedComponent)
  reportObj!: PowerBIReportEmbedComponent;
  @ViewChild('embedcontainer', { static: false })
  reportContainerDiv!: ElementRef;

  isEmbedded = false;
  reportClass = 'report-container'; // see styles.css in root folder

  displayOptions: IDisplayOption[] = [
    {
      name: 'Fit to width',
      value: pbi.models.DisplayOption.FitToWidth,
      icon: 'fit_screen',
    },
    {
      name: 'Fit to page',
      value: pbi.models.DisplayOption.FitToPage,
      icon: 'height',
    },
    {
      name: 'Actual size',
      value: pbi.models.DisplayOption.ActualSize,
      icon: 'aspect_ratio',
    },
  ];

  selectedDisplayOption = this.displayOptions[0];

  eventHandlersMap = new Map([
    [
      'loaded',
      () => {
        console.log('Report has loaded');
      },
    ],
    ['rendered', () => console.log('Report has rendered')],
    [
      'error',
      (event?: pbi.service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => {}],
  ]) as Map<
    string,
    (
      event?: pbi.service.ICustomEvent<any>,
      embeddedEntity?: pbi.Embed
    ) => void | null
  >;

  config = {
    type: 'report',
    id: 'PLACEHOLDER',
    embedUrl: 'PLACEHOLDER',
    accessToken: 'PLACEHOLDER',
    tokenType: pbi.models.TokenType.Aad,
    permissions: pbi.models.Permissions.All,
    settings: {
      layoutType: pbi.models.LayoutType.Custom,
      customLayout: {
        displayOption: this.selectedDisplayOption.value,
      },
      navContentPaneEnabled: false,
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      background: pbi.models.BackgroundType.Default,
    },
  };

  applicationStateService = inject(ApplicationStateService);
  authService = inject(MsalService);
  router = inject(Router);
  title = inject(Title);

  activeWorkspace$ = this.applicationStateService.activeWorkspace$;
  activeReport$ = this.applicationStateService.activeReport$;

  constructor() {}

  ngOnInit(): void {
    let activeReport$ = this.applicationStateService.activeReport$;
    let tokenRequest: SilentRequest = {
      scopes: ['https://analysis.windows.net/powerbi/api/.default'],
      account: this.authService.instance.getActiveAccount() ?? undefined,
    };
    let acquireAccessToken$ = from(
      this.authService.instance.acquireTokenSilent(tokenRequest)
    );

    combineLatest([this.activeWorkspace$, activeReport$, acquireAccessToken$])
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: ([workspace, report, response]) => {
          if (report && response) {
            this.title.setTitle(`Blue Ocean - ${workspace?.displayName} -  ${report.name}`);
            this.embedReport(report, response.accessToken);
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  embedReport(report: PowerBiApi.Report, accessToken: string) {
    this.config = {
      ...this.config,
      id: report.id,
      embedUrl: report.embedUrl + `&ctid=99c75c69-9ef2-4c4e-b5e3-54b6cf95c977`,
      accessToken: accessToken,
    };
    // destroy existing report before embedding a new one
    const reportContainer = this.reportContainerDiv.nativeElement;
    this.powerbi.reset(reportContainer);
    // embed the report.
    this.powerbi.embed(reportContainer, this.config);
    this.isEmbedded = true;
  }

  changeDisplayOption(option: IDisplayOption) {
    this.selectedDisplayOption = option;
    this.config.settings.customLayout.displayOption = option.value;
    this.powerbi.reset(this.reportContainerDiv.nativeElement);
    this.powerbi.embed(this.reportContainerDiv.nativeElement, this.config);
  }

  goBack() {
    // this.router.navigate(['/dashboard']);
    window.history.back();
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }
}

interface IDisplayOption {
  name: string;
  value: pbi.models.DisplayOption;
  icon: string;
}
